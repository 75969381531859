import React from "react";
import { Helmet } from "react-helmet";

const MetaManager = ({
  title = "",
  description = "",
  keywords = "",
  author = "",
  seo = false,
}) => {
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {author ? <meta name="author" content={author} /> : null}
    </Helmet>
  );
};

export default MetaManager;
