import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Logo from "../../assets/images/Logo.png";
import { API_END_POINT, companyName } from "../../Config/config";
import { useParams } from "react-router-dom";
import samplePaymentResults from "../../assets/Data/samplePaymentResults.json";
function Receipt() {
  const { leadId } = useParams();
  const [paymentData, setPaymentData] = useState({});
  const [currentPlanData, setCurrentPlanData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  function formatService(companyName) {
    if (companyName?.startsWith("/")) {
      companyName = companyName?.substring(1);
    }
    let words = companyName?.split("-");
    let formattedName = words
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedName
      ?.replace(/^\/+/, "")
      ?.replace(/\/+$/, "")
      ?.split("/")
      ?.pop();
  }
  const printReceipt = () => {
    const input = document.getElementById("receipt");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, -heightLeft, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("receipt.pdf");
    });
  };
  const getData = async () => {
    const API = `${API_END_POINT}payment/getOrderDetails.php?orderId=${leadId}`;
    console.log(API)
    await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: "no-cors",
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.status == "success") {
          setPaymentData(resJson);
          resJson?.plan?.plans.map((item) => {
            if (item?.id == resJson?.result?.plan_id) {
              setCurrentPlanData(item);
            }
          });
        } else setErrorMessage(resJson.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {paymentData?.zazor?.status == "paid" ? (
        <div
          className="max-w-3xl mx-auto p-6 bg-white rounded shadow-sm my-6"
          id="receipt"
        >
          <div className="grid grid-cols-2 items-center">
            <div>
              <img src={Logo} alt="company-logo" height="100" width="100" />
            </div>

            <div className="text-right">
              <p>{companyName}</p>
              <p className="text-gray-500 text-sm mt-1">
                GSTIN: 19JILPK9398K1ZX
              </p>
              <p className="text-gray-500 text-sm">info@golegalindia.com</p>
              <p className="text-gray-500 text-sm mt-1">+91 82404 41937</p>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center mt-8">
            <div>
              <p className="font-bold text-gray-800">Bill to :</p>
              <p className="text-gray-500">
                {paymentData?.result?.name},
                <br />
                {paymentData?.result?.state},
              </p>
              <p className="text-gray-500">{paymentData?.result?.phone}.</p>
              {paymentData?.result?.gst_in && (
                <p className="text-gray-500">
                  GSTIN: {paymentData?.result?.gst_in}
                </p>
              )}
            </div>

            <div className="text-right">
              <p className="">
                Invoice number:
                <span className="text-gray-500">
                  {" "}
                  #{paymentData?.result?.id}
                </span>
              </p>
              <p>
                Invoice date:{" "}
                <span className="text-gray-500">
                  {paymentData?.result?.last_update?.split(" ")[0]}
                </span>
              </p>
            </div>
          </div>
          <div className="-mx-4 mt-8 flow-root sm:mx-0">
            <table className="min-w-full">
              <colgroup>
                <colgroup>
                  <col className="w-full sm:w-1/2" />
                  <col className="sm:w-1/6" />
                  <col className="sm:w-1/6" />
                  <col className="sm:w-1/6" />
                </colgroup>
              </colgroup>
              <thead className="border-b border-gray-300 text-gray-900">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Items
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200">
                  <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="font-medium text-gray-900">
                      {formatService(currentPlanData.service_type)}
                    </div>
                    <div className="mt-1 truncate text-gray-500">
                      {formatService(currentPlanData.plan_name)}
                    </div>
                  </td>
                  <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                    1
                  </td>
                  <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                    &#x20B9;{currentPlanData.discount_price}
                  </td>
                  <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                    &#x20B9;{currentPlanData.discount_price}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th
                    scope="row"
                    colSpan="3"
                    className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                  >
                    Subtotal
                  </th>
                  <th
                    scope="row"
                    className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                  >
                    Subtotal
                  </th>
                  <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">
                    &#x20B9;{currentPlanData.discount_price}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    colSpan="3"
                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                  >
                    Tax
                  </th>
                  <th
                    scope="row"
                    className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                  >
                    Tax
                  </th>
                  <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                    &#x20B9;
                    {Math.round(
                      (paymentData?.result?.gst / 100) *
                        paymentData?.result?.amount
                    )}{" "}
                    ({paymentData?.result?.gst}%)
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    colSpan="3"
                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                  >
                    Discount
                  </th>
                  <th
                    scope="row"
                    className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                  >
                    Discount
                  </th>
                  <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                    {currentPlanData?.discount_title}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    colSpan="3"
                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                  >
                    Total
                  </th>
                  <th
                    scope="row"
                    className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                  >
                    Total
                  </th>
                  <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                    &#x20B9;
                    {Number(
                      Math.round(paymentData?.result?.amount) +
                        Math.round(
                          (paymentData?.result?.amount *
                            paymentData?.result?.gst) /
                            100
                        )
                    ).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="flex justify-center items-center my-5">
            <a
              href={`${API_END_POINT}payment/downloadReceipt.php?orderId=${leadId}`}
              download={true}
              // onClick={()=>window.}
              className="bg-red-500 text-white font-bold py-2 px-4 rounded"
            >
              Download Receipt
            </a>
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center text-center"
          style={{
            height: window.innerHeight - window.innerHeight * 0.18,
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </div>
      )}
    </>
  );
}

export default Receipt;
